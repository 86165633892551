import uniqBy from 'lodash.uniqby'
import { HYDRATE } from 'next-redux-wrapper'

import { keyForResource } from '@models/APIResource'
import { IntoNode } from '@models/IntoNode'
import { IntoUrl } from '@models/IntoUrl'
import RecommendResponse from '@models/RecommendResponse'
import feedApi from '@redux/api/feedApi'
import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit'
import { AppState } from '../store/store'

export const feedConstants = {
  GRID_PAGE_SIZE_LOGGED_IN: 10,
  GRID_PAGE_SIZE_LOGGED_OUT: 5,
  FULL_SCREEN_PAGE_SIZE: 5,
  RELATED_GRID_PAGE_SIZE: 6,
  NODE_GRID_PAGE_SIZE: 6,
  LOAD_MORE_THRESHOLD: 3,
}

interface FeedSlice {
  _metadata: Metadata
  _instanceId: string | null
  streamUrlId: string | number | null
  currentFeedItemKey: string | null
  lastFeedItemKey: string | null
  hasReachedEnd: boolean
  identifier: IntoNode
  isAutoplayEnabled: boolean
  page: number
  pageSize: number
  showSidePanel: boolean
  isChangingFeed: boolean
  items: FeedItem[]
  customItems: CustomItem[]
}

interface Metadata {
  updatedFields: (keyof FeedSlice)[]
}

interface CustomItem {
  index: number
  items: FeedItem[]
}

const initialState: FeedSlice = {
  _metadata: { updatedFields: [] },
  _instanceId: null,
  page: 1,
  pageSize: 5,
  currentFeedItemKey: null,
  lastFeedItemKey: null,
  identifier: IntoNode.FEATURED,
  hasReachedEnd: false,
  isAutoplayEnabled: false,
  showSidePanel: false,
  isChangingFeed: false,
  streamUrlId: null,
  items: [],
  customItems: [],
}

// Utility function to update state fields and track updated fields
function updateField<K extends keyof FeedSlice>(state: FeedSlice, field: K, value: FeedSlice[K]): void {
  if (field === 'currentFeedItemKey') {
    // Update lastFeedItemKey before updating currentFeedItemKey.
    state.lastFeedItemKey = state.currentFeedItemKey
    if (!state._metadata.updatedFields.includes('lastFeedItemKey')) {
      state._metadata.updatedFields.push('lastFeedItemKey')
    }
  }
  state[field] = value
  if (!state._metadata.updatedFields.includes(field)) {
    state._metadata.updatedFields.push(field)
  }
}

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    incrementPage: {
      reducer: (state, action: PayloadAction<number | undefined>) => {
        const incrementBy = action.payload ?? 1
        updateField(state, 'page', state.page + incrementBy)
      },
      prepare: prepareAutoBatched<number | undefined>(),
    },
    setIdentifier: {
      reducer: (state, action: PayloadAction<IntoNode>) => {
        updateField(state, 'identifier', action.payload)
      },
      prepare: prepareAutoBatched<IntoNode>(),
    },
    setCurrentFeedItemKey: {
      reducer: (state, action: PayloadAction<string | null>) => {
        updateField(state, 'currentFeedItemKey', action.payload)
      },
      prepare: prepareAutoBatched<string | null>(),
    },
    setIsAutoplayEnabled: {
      reducer: (state, action: PayloadAction<boolean>) => {
        updateField(state, 'isAutoplayEnabled', action.payload)
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setPageSize: {
      reducer: (state, action: PayloadAction<number>) => {
        updateField(state, 'pageSize', action.payload)
      },
      prepare: prepareAutoBatched<number>(),
    },
    setShowSidePanel: {
      reducer: (state, action: PayloadAction<boolean>) => {
        updateField(state, 'showSidePanel', action.payload)
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setPageNumber: {
      reducer: (state, action: PayloadAction<number>) => {
        updateField(state, 'page', action.payload)
      },
      prepare: prepareAutoBatched<number>(),
    },
    setHasReachedEnd: {
      reducer: (state, action: PayloadAction<boolean>) => {
        updateField(state, 'hasReachedEnd', action.payload)
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setInstanceId: {
      reducer: (state, action: PayloadAction<string>) => {
        updateField(state, '_instanceId', action.payload)
      },
      prepare: prepareAutoBatched<string>(),
    },
    setStreamUrlId: {
      reducer: (state, action: PayloadAction<string | number | null>) => {
        updateField(state, 'streamUrlId', action.payload)
      },
      prepare: prepareAutoBatched<string | number | null>(),
    },
    setIsChangingFeed: {
      reducer: (state, action: PayloadAction<boolean>) => {
        updateField(state, 'isChangingFeed', action.payload)
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    resetFeedState: () => initialState,
    insertFeedItems: {
      reducer: (state, action: PayloadAction<{ index: number; items: FeedItem[] }>) => {
        const { index, items } = action.payload
        // Insert items at the specified index.
        state.items.splice(index, 0, ...items)
        state.items = uniqBy(state.items, keyForResource)
      },
      prepare: prepareAutoBatched<{ index: number; items: FeedItem[] }>(),
    },
    addCustomItem: {
      reducer: (state, action: PayloadAction<{ index: number; items: FeedItem[] }>) => {
        const newItem = action.payload
        state.customItems = state.customItems.filter(existingItem => existingItem.index !== newItem.index)

        const existingItemIds = new Set(
          state.customItems.flatMap(customItem => customItem.items.map(item => keyForResource(item)))
        )

        const uniqueItems = uniqBy([...newItem.items], keyForResource).filter(
          item => !existingItemIds.has(keyForResource(item))
        )

        if (uniqueItems.length > 0) {
          state.customItems.push({
            index: newItem.index,
            items: uniqueItems,
          })
        }
      },
      prepare: prepareAutoBatched<{ index: number; items: FeedItem[] }>(),
    },
    clearCustomItems: {
      reducer: state => {
        state.customItems = []
      },
      prepare: prepareAutoBatched<undefined>(),
    },
    goToNextFeedItem: {
      reducer: state => {
        const currentIndex = state.items.findIndex(item => keyForResource(item) === state.currentFeedItemKey)
        if (currentIndex >= 0 && currentIndex < state.items.length - 1) {
          const nextItemKey = String(keyForResource(state.items[currentIndex + 1]))
          updateField(state, 'currentFeedItemKey', nextItemKey)
        }
      },
      prepare: prepareAutoBatched<undefined>(),
    },
    setCurrentFeedItemKeyToFirstUrl: {
      reducer: state => {
        // Find the first 'url' item in the feed items.
        const firstUrlItem = state.items.find(item => item.type === 'url') as IntoUrl | undefined
        if (firstUrlItem) {
          updateField(state, 'currentFeedItemKey', String(keyForResource(firstUrlItem)))
        } else {
          updateField(state, 'currentFeedItemKey', null)
        }
      },
      prepare: prepareAutoBatched<undefined>(),
    },
    clearAllItemsExceptCurrent: {
      reducer: (state, _action: PayloadAction<undefined>) => {
        if (state.currentFeedItemKey) {
          const currentItem = state.items.find(item => keyForResource(item) === state.currentFeedItemKey)
          if (currentItem) {
            state.items = [currentItem]
            updateField(state, 'items', state.items)
          }
        }
      },
      prepare: prepareAutoBatched<undefined>(),
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverFeed = action.payload.feed
        const fieldsToCheck: (keyof FeedSlice)[] = [
          '_instanceId',
          'currentFeedItemKey',
          'hasReachedEnd',
          'identifier',
          'isAutoplayEnabled',
          'page',
          'pageSize',
          'showSidePanel',
          'streamUrlId',
          'isChangingFeed',
        ]

        fieldsToCheck.forEach(field => {
          if (serverFeed._metadata.updatedFields.includes(field) && serverFeed[field] !== state[field]) {
            updateField(state, field, serverFeed[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
    builder.addMatcher(feedApi.endpoints.getFeedData.matchFulfilled, (state, action) => {
      const newItems = action.payload.items

      // If we are in "changing feed" mode, overwrite existing items and set currentFeedItemKey
      if (state.isChangingFeed) {
        state.items = newItems
        state.hasReachedEnd = newItems.length < state.pageSize
        if (newItems.length > 0) {
          updateField(state, 'currentFeedItemKey', String(keyForResource(newItems[0])))
        } else {
          updateField(state, 'currentFeedItemKey', null)
        }
        updateField(state, 'isChangingFeed', false)
      } else {
        // Otherwise, append new items (for e.g. infinite scrolling in VerticalFeed)
        state.items = [...state.items, ...newItems]
        state.items = uniqBy(state.items, keyForResource)

        // Update hasReachedEnd based on response.
        if (newItems.length < state.pageSize) state.hasReachedEnd = true
      }
    })
  },
})

// Generalized selector to get an item by relative index
const selectItemByRelativeIndex = (offset: number) =>
  createSelector([selectCurrentFeed, (state: AppState) => state.feed.currentFeedItemKey], (feed, currentItemKey) => {
    if (!feed || !currentItemKey) return undefined

    const currentItemIndex = feed.items.findIndex(item => keyForResource(item) === currentItemKey)

    if (currentItemIndex === -1) return undefined

    const targetIndex = currentItemIndex + offset

    if (targetIndex < 0 || targetIndex >= feed.items.length) return undefined

    return feed.items[targetIndex]
  })

const selectFeedKey = createSelector(
  (state: AppState) => state.feed._instanceId,
  (state: AppState) => state.feed.identifier,
  (state: AppState) => state.user.userId,
  (_instanceId, identifier, userId) => {
    return _instanceId === 'likes'
      ? `getLikedUrls:${userId}`
      : _instanceId === 'posts'
        ? `getPostedUrls:${userId}`
        : `getFeedData:${identifier.slug}:${_instanceId}`
  }
)

export const selectCurrentFeed = (state: AppState) => {
  const {
    api: { queries },
  } = state
  const queriesKey = selectFeedKey(state)
  const query = queries[queriesKey]
  const data = query?.data as RecommendResponse | undefined
  return data?.items !== undefined ? data : undefined
}

export const selectCurrentFeedItem = createSelector(
  selectCurrentFeed,
  (state: AppState) => state.feed.currentFeedItemKey,
  (feed, currentItemKey) => {
    return feed?.items.find(item => keyForResource(item) === currentItemKey)
  }
)

export const selectCurrentFeedStatus = (state: AppState) => {
  const {
    api: { queries },
  } = state
  const queriesKey = selectFeedKey(state)
  const query = queries[queriesKey]
  return query?.status
}

export const selectFilteredIds = createSelector(selectCurrentFeed, currentFeed => {
  return currentFeed?.items.reduce(
    (acc, item) => {
      const key = keyForResource(item)
      if (key) {
        acc.push(key)
      }
      return acc
    },
    [] as (string | number)[]
  )
})

export const selectCurrentItemIndex = createSelector(
  selectCurrentFeed,
  (state: AppState) => state.feed.currentFeedItemKey,
  (currentFeed, currentItemKey) => {
    if (!currentFeed?.items || !currentItemKey) return undefined
    const index = currentFeed.items.findIndex(item => keyForResource(item) === currentItemKey)
    return index >= 0 ? index : undefined
  }
)

export const selectFeedUrls = createSelector(selectCurrentFeed, (feed): IntoUrl[] => {
  return (feed?.items.filter(item => item.type === 'url') as IntoUrl[]) || []
})

export const selectCurrentUrlIndex = createSelector(
  selectFeedUrls,
  (state: AppState) => state.feed.lastFeedItemKey,
  (state: AppState) => state.feed.currentFeedItemKey,
  (feedUrls, lastFeedItemKey, currentItemKey) => {
    if (!feedUrls || !currentItemKey) return undefined

    // Try to find the index of the currentItemKey in feedUrls
    const currentUrlIndex = feedUrls.findIndex(item => String(item.url_id) === String(currentItemKey))

    if (currentUrlIndex !== -1) {
      // If currentItemKey is a URL, return its index
      return currentUrlIndex
    }
    // currentItemKey is not a URL
    // Find the index of lastFeedItemKey in feedUrls
    const lastUrlIndex = feedUrls.findIndex(item => String(item.url_id) === String(lastFeedItemKey))

    // If lastFeedItemKey is not found in feedUrls, return undefined
    if (lastUrlIndex === -1) return undefined

    // Find indexes of currentItemKey and lastFeedItemKey in the main feed items
    const currentItemIndex = feedUrls.findIndex(item => keyForResource(item) === currentItemKey)
    const lastItemIndex = feedUrls.findIndex(item => keyForResource(item) === lastFeedItemKey)

    if (currentItemIndex === -1 || lastItemIndex === -1) {
      // If either item is not found, return undefined
      return undefined
    }

    // Determine direction based on the positions of current and last items
    const direction = currentItemIndex > lastItemIndex ? 'forward' : 'backward'

    // Adjust lastUrlIndex based on the direction
    if (direction === 'forward' && lastUrlIndex + 1 < feedUrls.length) {
      return lastUrlIndex + 1
    }
    if (direction === 'backward' && lastUrlIndex - 1 >= 0) {
      return lastUrlIndex - 1
    }
    return undefined
  }
)

export const selectActiveUrlIndex = selectCurrentItemIndex

export const selectCurrentUrl = createSelector(
  selectFeedUrls,
  (state: AppState) => state.feed.currentFeedItemKey,
  (feedUrls, currentItemKey) =>
    feedUrls?.find(item => String(item.url_id) === String(currentItemKey)) as IntoUrl | undefined
)

export const selectCurrentItem = selectItemByRelativeIndex(0)

export const selectNextItem = selectItemByRelativeIndex(1)

export const selectPreviousItem = selectItemByRelativeIndex(-1)

// Selector to get the last current item key
export const selectLastFeedItemKey = (state: AppState) => state.feed.lastFeedItemKey

// Selector to get the current item key
export const selectCurrentFeedItemKey = (state: AppState) => state.feed.currentFeedItemKey

type FeedItem = RecommendResponse['items'][number]
type FeedItemType = FeedItem['type']
type TypeToItem<T extends FeedItemType> = Extract<FeedItem, { type: T }>
type FilteredFeed<T extends FeedItemType[]> = Extract<FeedItem, { type: T[number] }>[]

export const selectFilteredFeed = <T extends FeedItemType[]>(types: [...T]) =>
  createSelector(selectCurrentFeed, (feed): FilteredFeed<T> => {
    return feed?.items.filter((item): item is Extract<FeedItem, { type: T[number] }> => types.includes(item.type)) || []
  })

export const selectAdjacentNItemsOfType = <T extends FeedItemType>(
  n: number,
  type: T,
  direction: 'next' | 'previous'
) =>
  createSelector(
    [selectCurrentFeed, (state: AppState) => state.feed.currentFeedItemKey],
    (feed, currentItemKey): TypeToItem<T>[] | undefined => {
      if (!feed || !currentItemKey) return undefined

      const items = feed.items
      const currentItemIndex = items.findIndex(item => keyForResource(item) === currentItemKey)

      if (currentItemIndex === -1) return undefined

      const itemsToReturn: TypeToItem<T>[] = []

      let index = currentItemIndex

      while (itemsToReturn.length < n) {
        index = direction === 'next' ? index + 1 : index - 1

        if (index < 0 || index >= items.length) break

        const item = items[index]
        if (item && item.type === type) {
          itemsToReturn.push(item as TypeToItem<T>)
        }
      }

      if (direction === 'previous') {
        itemsToReturn.reverse()
      }

      return itemsToReturn.length > 0 ? itemsToReturn : []
    }
  )

export const selectNextNItemsOfType = <T extends FeedItemType>(n: number, type: T) =>
  selectAdjacentNItemsOfType(n, type, 'next')

export const selectPreviousNItemsOfType = <T extends FeedItemType>(n: number, type: T) =>
  selectAdjacentNItemsOfType(n, type, 'previous')

export const selectMobileFeed = <T extends FeedItemType[]>(
  feedItemTypes: [...T],
  customInitialFeedItem?: TypeToItem<T[number]>
) =>
  createSelector(
    [(state: AppState) => state.feed, selectFilteredFeed(feedItemTypes)],
    (feed, currentFeed): TypeToItem<T[number]>[] => {
      if (!currentFeed) return []

      const feedItems = currentFeed.slice()

      feed.customItems
        .filter(({ index }) => index < feedItems.length)
        .forEach(({ index, items }) => {
          feedItems.splice(
            index,
            0,
            ...items.filter((item): item is Extract<FeedItem, { type: T[number] }> => feedItemTypes.includes(item.type))
          )
        })

      if (customInitialFeedItem) feedItems.unshift(customInitialFeedItem)

      return feedItems
    }
  )

export const {
  incrementPage,
  setPageSize,
  setIsAutoplayEnabled,
  setShowSidePanel,
  setCurrentFeedItemKey,
  setIdentifier,
  setPageNumber,
  setHasReachedEnd,
  setInstanceId,
  setStreamUrlId,
  setIsChangingFeed,
  resetFeedState,
  insertFeedItems,
  addCustomItem,
  clearCustomItems,
  goToNextFeedItem,
  setCurrentFeedItemKeyToFirstUrl,
  clearAllItemsExceptCurrent,
} = feedSlice.actions

export default feedSlice.reducer
