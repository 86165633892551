import React, { useMemo, useRef } from 'react'

import { UserList, UserListLoading, UserListRef } from '@desktop/UserList'
import { IntoUrl } from '@models/IntoUrl'
import urlApi from '@redux/api/urlApi'

const FOLLOWING_LIKER_LIMIT = 2
const TOTAL_PROMPTS = 3
const USER_LOADING_LIMIT = 4
const RandomLikePromptCopy = (): string[] => {
  const randomIndex = Math.floor(Math.random() * TOTAL_PROMPTS)
  return [
    ['Be the first to like it...', 'Mix gets better as you like or dislike'],
    ['Mix is always learning...', 'Like or dislike to improve your recommendations.'],
    ['Share with friends...', 'Mix is better with friends!'],
  ][randomIndex]
}

interface UrlLikersProps {
  url: IntoUrl
  likedByUsername?: string | null
}

export const UrlLikersLoading = () => (
  <div>
    <span className="mb-2 block h-4 w-[150px] animate-pulse rounded bg-contrast/10"></span>
    <UserListLoading loadingLimit={4} />
  </div>
)

export const UrlLikers: React.FC<UrlLikersProps> = ({ url, likedByUsername }) => {
  const { isLoading: isLoadingLikers, data: dataLikers = [] } = urlApi.useGetLikersQuery({
    urlId: url.url_id,
    cookie: null,
  })
  const userListRef = useRef<UserListRef>(null)

  const followingLikers = useMemo(
    () => dataLikers.filter(liker => liker.meta.isLiked).slice(0, FOLLOWING_LIKER_LIMIT),
    [dataLikers]
  )

  const likers = useMemo(
    () => dataLikers.filter(liker => liker.username !== likedByUsername),
    [dataLikers, likedByUsername]
  )

  const hasFollowedLikers = followingLikers?.length > 0
  const remainingLikers = likers?.length - followingLikers?.length
  const hasAdditionalLikers = remainingLikers > 0
  const likerText = useMemo(() => {
    if (hasFollowedLikers) {
      const displayNames = [...followingLikers.map(liker => liker.display_name)]
      if (hasAdditionalLikers) return displayNames.join(', ').concat(' and ')
      return followingLikers?.length > 1
        ? [displayNames.pop(), displayNames.join(', ')].reverse().join(' and ')
        : displayNames
    }
  }, [hasFollowedLikers, hasAdditionalLikers, followingLikers])
  const additionalLikersButton = useMemo(() => {
    return hasAdditionalLikers ? (
      <button onClick={() => userListRef.current?.toggleUserModalVisibility()} className="underline">
        {remainingLikers}{' '}
        {hasFollowedLikers ? `other${remainingLikers > 1 ? 's' : ''}` : remainingLikers > 1 ? 'people' : 'person'}
      </button>
    ) : null
  }, [hasAdditionalLikers, remainingLikers, hasFollowedLikers])
  const randomCopy = useMemo(RandomLikePromptCopy, [])

  const likerHeader = useMemo(() => {
    if (isLoadingLikers) return <span className="mb-2 block h-5 w-[150px] animate-pulse rounded bg-contrast/10"></span>
    if (likers?.length === 0)
      return (
        <>
          <p className="font-medium text-accent opacity-90 light:text-accent-dark">{randomCopy[0]}</p>
          <p className="font-medium opacity-60">{randomCopy[1]}</p>
        </>
      )

    return (
      <div className="mb-2 text-sm text-primary/50">
        {likerText} {additionalLikersButton} {likers?.length === 1 ? 'likes' : 'like'} this
      </div>
    )
  }, [isLoadingLikers, likers, likerText, additionalLikersButton, randomCopy])

  return (
    <div>
      {likerHeader}
      {isLoadingLikers ? (
        <UserListLoading loadingLimit={USER_LOADING_LIMIT} />
      ) : (
        <UserList ref={userListRef} users={likers} />
      )}
    </div>
  )
}
