import { ReactElement, useCallback } from 'react'
import toast from 'react-hot-toast'
import { useImmer } from 'use-immer'

import { IconThumb } from '@common/IconThumb'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import LoginButton from '@desktop/login/LoginButton'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import useFeedNavigation from '@hooks/useFeedNavigation'
import useKeyboardShortcut from '@hooks/useKeyboardShortcut'
import { useToggleRating } from '@pages/url/hooks/useToggleRating'
import urlApi from '@redux/api/urlApi'
import { useAppSelector } from '@redux/store/store'

const DEFAULT_ICON_SIZE = 24
const NEXT_NAVIGATION_DELAY_MS = 500

export const UrlRateUpButton = ({
  urlId,
  buttonClassName,
  fullOpacity = false,
  size = DEFAULT_ICON_SIZE,
}: {
  urlId: string | undefined
  buttonClassName?: string
  fullOpacity?: boolean
  size?: number
}) => {
  const { isLoggedIn } = useCurrentUserContext()
  const currentFeedItemKey = useAppSelector(state => state.feed.currentFeedItemKey)
  const { rateUrlNone, rateUrlUp } = useToggleRating(currentFeedItemKey)
  const trackClick = useTrackClick()
  const [forceShowModal, setForceShowModal] = useImmer(false)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const url = urlApi.useGetUrlDetailsQuery({ urlId: urlId! }, { skip: !urlId }).data?.url[0]
  const isUrlLiked = url?.meta.isInto

  //#region Keyboard Handling
  const rateUpHandler = useCallback(() => {
    if (!isLoggedIn) {
      setForceShowModal(true)
      return
    }
    if (isUrlLiked) {
      trackClick('urlRemoveLike')
      rateUrlNone().catch(() => {})
      return
    }
    trackClick('urlLike')
    rateUrlUp().catch(() => {})
  }, [isLoggedIn, setForceShowModal, rateUrlUp, rateUrlNone, isUrlLiked, trackClick])

  useKeyboardShortcut({
    keys: {
      l: rateUpHandler,
    },
  })
  //#endregion

  return (
    <div className="group relative">
      <LoginButton
        onClickIfLoggedIn={rateUpHandler}
        className={[
          'z-10 flex items-center justify-center p-3 transition-all hover:scale-105',
          isUrlLiked || fullOpacity ? 'opacity-100' : 'opacity-50',
          buttonClassName,
        ].join(' ')}
        forceShowModal={forceShowModal}
        setForceShowModal={setForceShowModal}
      >
        <IconThumb direction={'up'} isSelected={isUrlLiked ?? false} size={size} />
      </LoginButton>
      <span className="invisible absolute mt-1 -translate-x-1 translate-y-0 scale-50 whitespace-nowrap rounded bg-tooltip px-1.5 py-0.5 text-xs font-semibold text-tooltip opacity-0 transition-all duration-150 group-hover:translate-y-1.5 group-hover:scale-100 group-hover:opacity-100 md:group-hover:visible">
        Like (L)
      </span>
    </div>
  )
}
export const UrlRateDownButton = ({
  urlId,
  buttonClassName,
  fullOpacity = false,
  size = DEFAULT_ICON_SIZE,
}: {
  urlId: string | undefined
  buttonClassName?: string
  fullOpacity?: boolean
  size?: number
}) => {
  const { isLoggedIn } = useCurrentUserContext()
  const currentFeedItemKey = useAppSelector(state => state.feed.currentFeedItemKey)
  const { handleNextNavigation } = useFeedNavigation()
  const [forceShowModal, setForceShowModal] = useImmer(false)

  const { rateUrlNone, rateUrlDown } = useToggleRating(currentFeedItemKey)
  const trackClick = useTrackClick()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const url = urlApi.useGetUrlDetailsQuery({ urlId: urlId! }, { skip: !urlId }).data?.url[0]
  const isUrlDisliked = url?.meta.isDisliked

  //#region Keyboard Handling
  const rateDownHandler = useCallback(() => {
    if (!isLoggedIn) {
      setForceShowModal(true)
      return
    }
    if (isUrlDisliked) {
      trackClick('urlRemoveDislike')
      rateUrlNone().catch(() => {})
      return
    }
    trackClick('urlDislike')
    rateUrlDown().catch(() => {})
    toast(`You'll see less like that`, { position: 'top-center', duration: 2000, className: 'font-semibold mt-14' })
    setTimeout(() => Promise.resolve(handleNextNavigation()).catch(() => {}), NEXT_NAVIGATION_DELAY_MS)
  }, [isLoggedIn, setForceShowModal, rateUrlDown, rateUrlNone, isUrlDisliked, trackClick, handleNextNavigation])

  useKeyboardShortcut({
    keys: {
      d: rateDownHandler,
    },
  })
  //#endregion

  return (
    <div className="group relative">
      <LoginButton
        onClickIfLoggedIn={rateDownHandler}
        forceShowModal={forceShowModal}
        setForceShowModal={setForceShowModal}
        className={[
          'z-10 flex items-center justify-center p-3 transition-all hover:scale-105',
          isUrlDisliked || fullOpacity ? 'opacity-100' : 'opacity-50',
          buttonClassName,
        ].join(' ')}
      >
        <IconThumb direction={'down'} isSelected={isUrlDisliked === true} size={size} />
      </LoginButton>
      <span className="invisible absolute mt-1 -translate-x-3 translate-y-0 scale-50 whitespace-nowrap rounded bg-tooltip p-1 px-1.5 py-0.5 text-xs font-semibold text-tooltip opacity-0 transition-all duration-150 group-hover:translate-y-1.5 group-hover:scale-100 group-hover:opacity-100 md:group-hover:visible">
        Dislike (D)
      </span>
    </div>
  )
}

export const UrlActions = ({ urlId, children }: { urlId: string | undefined; children?: ReactElement }) => {
  const { isUrlLikesEnabled } = useAppSelector(state => state.header)

  if (!isUrlLikesEnabled) return children
  return (
    <div className="flex h-12">
      <div className="relative flex items-center rounded-full bg-contrast/10 px-2 py-1">
        {/* Down Thumb Button */}
        <UrlRateDownButton urlId={urlId} buttonClassName="-translate-x-0.5 translate-y-0.5 opacity-40 size-11" />

        {children}

        {/* Up Thumb Button */}
        <UrlRateUpButton urlId={urlId} buttonClassName="translate-x-0.5 size-11" />
      </div>
    </div>
  )
}
